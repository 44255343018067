import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../consts';

export const PaperColumns = StyleSheet.create({
  tableContainer: {
    backgroundColor: colors.paymentData.bg,
    padding: 30,
  },
  tableContainerForm: {
    backgroundColor: colors.paymentData.bg,
    height: 400,
    paddingVertical: 30,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
    paddingHorizontal: 20,
  },
  columnForm: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
    paddingLeft: 50,
    paddingRight: 20,
  },
  columnFormRight: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
    paddingLeft: 20,
    paddingRight: 50,
  },
});
