import { productRole } from '@/types/productRoles';
import { DatatableHeader } from '../../components/datatable';
import { ColumnComponents } from '../../components/datatable/header/datatableHeader.types';

export const productsListPageTabHeader: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '12%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'category',
    label: 'Categoria',
    width: '12%',
  },
  {
    isOrderResultEnabled: false,
    key: 'quantity',
    label: 'Quantidade Disponível',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'price',
    label: 'Preço',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'picture',
    label: 'Foto',
    width: '15%',
    renderComponent: ColumnComponents.Image,
  },
  {
    isOrderResultEnabled: false,
    key: 'sector',
    label: 'Setor',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];

export const productActiveInactive = [
  {
    label: 'Ativo',
    value: productRole.Activo,
  },
  {
    label: 'Inativo',
    value: productRole.Inactive,
  },
];

export const ascDesc = [
  {
    label: 'Padrão',
    value: 'default',
  },
  {
    label: 'Ascendente',
    value: 'asc',
  },
  {
    label: 'Descendente',
    value: 'desc',
  },
];
