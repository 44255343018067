import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { Responsible } from '@/types/responsible';
import { useGetProductCategories } from '@/api/categories';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { formatOptions } from '@/utils/currencyMask';
import {
  useCreateProduct,
  useGetProduct,
  useUpdateProduct,
} from '@/api/products';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import { currencyFormat, currencyFormatFloat } from '@/utils/strUtils';
import { Flows } from '@/navigation/routes/enums/Flows';
import { useGetSectors } from '@/api/sector/hooks';
import { Sector } from '@/api/sector/dto';

export const useProductsCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: categories, isLoading: categoriesLoading } =
    useGetProductCategories(entity, !!entity);
  const { data: productToEdit, isLoading: productToEditLoading } =
    useGetProduct(id, !!id);
  const { data: sectors } = useGetSectors(entity, !!entity);

  const { goToEntityPage, goBack } = useNavigate();

  // step 1
  const [categoriesSearchList, setCategoriesSearchList] = useState<any[]>([]);
  const [sectorsSearchList, setSectorsSearchList] = useState<any[]>([]);
  const [category, setCategory] = useState('');
  const [discount, setDiscount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [sector, setSector] = useState('');
  const [sectorId, setSectorId] = useState('');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isvariablePrice, setIsVariablePrice] = useState(false);
  const [isHighlightedString, setIsHighlightedString] = useState('');
  const [variablePriceString, setVariablePriceString] = useState('');
  const [highlightedOptionsList] = useState<
    { label: string; value: boolean }[]
  >([
    {
      label: 'Não',
      value: false,
    },
    {
      label: 'Sim',
      value: true,
    },
  ]);
  const [variablePriceList] = useState<{ label: string; value: boolean }[]>([
    {
      label: 'Não',
      value: false,
    },
    {
      label: 'Sim',
      value: true,
    },
  ]);

  const [categoryId, setCategoryId] = useState('');
  const [name, setName] = useState('');

  // step 2
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState<number | null>(0);
  const [price, setPrice] = useState<number | null>(0);
  const [quantity, setQuantity] = useState('');
  const [stock, setStock] = useState('');

  // step 3
  const [picture, setPicture] = useState('');
  const [imageCache, setImageCache] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const queryClient = useQueryClient();
  const mutator = useCreateProduct();
  const mutatorUpdate = useUpdateProduct();

  const goToCreateCategory = () => {
    goToEntityPage(Routes.Category, {
      screen: Routes.CategoryCreate,
      params: { entity, flow: Flows.product },
    });
  };

  const onImagePick = (file: File, uri: string) => {
    setImageCache(uri);
    setPicture('');
  };

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
  };

  const goToStep2 = () => {
    if (isvariablePrice) {
      setStep1(true);
      setStep2(true);
      setStep3(true);
    } else {
      setStep1(true);
      setStep2(true);
      setStep3(false);
      setDisable(true);
    }
  };

  const goToStep3 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(true);
  };

  const onChangeDiscount = (valor: number) => {
    const currentPrice = price ?? 0;
    const amount = (valor / 100) * currentPrice;
    const discountedPrice = currentPrice - amount;
  
    setDiscountPrice(valor > 0 ? discountedPrice : 0);
    setDiscount(valor);
  };

  const onChangePrice = (val: number) => {
    setPrice(val)
    setDiscount(0)
    setDiscountPrice(0)
  }

  const formatList = () => {
    const list = categories?.map((responsible: Responsible) => ({
      label: responsible.name,
      value: responsible.id,
    }));

    const listSorted = list.sort((a: any, b: any) =>
      a.label.localeCompare(b.label),
    );
    setCategoriesSearchList(listSorted);

    // faz uma lista para os setores também
    const listSectors = sectors?.map((sectorr: Sector) => ({
      label: sectorr.name,
      value: sectorr.id,
    }));

    const listSortedSectors = listSectors?.sort((a: any, b: any) =>
      a.label.localeCompare(b.label),
    );
    setSectorsSearchList(listSortedSectors);
  };

  const onChangeSelect = (value: string, index: number) => {
    setVariablePriceString(value);
    setIsVariablePrice(variablePriceList[index].value);
  };

  const onChangeHighlighte = (value: any, index: number) => {
    setIsHighlightedString(value);
    setIsHighlighted(highlightedOptionsList[index].value);
  };

 const onChangeSearch = (InputValue: string) => {
  setSector(InputValue) 
  if (InputValue === '') {
    setSectorId('')
  }
 }

  const create = () =>
    mutator.mutateAsync({
      name,
      entity,
      description: isvariablePrice ? name : description,
      picture: imageCache,
      cost: isvariablePrice ? 0 :  currencyFormat(formatNumber(cost || 0, formatOptions)),
      price: isvariablePrice ? 0 :  currencyFormat(formatNumber(price || 0, formatOptions)),
      productCategory: categoryId,
      quantity: isvariablePrice ? 1000000 : parseInt(quantity, 10),
      stock: parseInt(stock, 10),
      isActive: true,
      isHighlighted,
      isvariablePrice,
      segment: sectorId,
      discountPrice: currencyFormat(formatNumber(discountPrice, formatOptions)),
    });

  const update = () =>
    mutatorUpdate.mutateAsync({
      id,
      name,
      entity,
      description: isvariablePrice ? name : description,
      picture: imageCache,
      cost: isvariablePrice
        ? 0
        : currencyFormat(formatNumber(cost || 0, formatOptions)),
      price: isvariablePrice
        ? 0
        : currencyFormat(formatNumber(price || 0, formatOptions)),
      productCategory: categoryId,
      quantity: isvariablePrice ? 1000000 : parseInt(quantity, 10),
      stock: parseInt(stock, 10),
      isActive: productToEdit?.isActive,
      isHighlighted,
      isvariablePrice,
      segment: sectorId,
      discountPrice: currencyFormat(formatNumber(discountPrice, formatOptions)),
    });

  const submit = async () => {
    setLoading(true);

    try {
      console.log('setor',sectorId)
      const response = id ? await update() : await create();
      const data = await response.json();

      if (response.status !== 201 && response.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code
          ? locale.t(`errors.${data.code}`)
          : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      setLoading(false);

      await queryClient.invalidateQueries('products');
      goToEntityPage(Routes.Product, {
        params: {
          entity,
        },
        screen: Routes.ProductList,
      });
    } catch (e) {
      console.error('useClientCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categories?.length && !categoriesLoading) {
      formatList();
    }
  }, [categories, sector]);

  useEffect(() => {
    goToStep1();
  }, []);

  useEffect(() => {
    if (productToEdit && !productToEditLoading) {
      const discountPri = productToEdit?.discountPrice  ?? 0;
      const productPrice = productToEdit?.price ?? 0;
      const discountAmount = discountPri > 0 
  ? Math.round(((productPrice - discountPri) / productPrice) * 100) 
  : 0;
  
      setName(productToEdit?.name);
      setCategory(productToEdit?.productCategory.name);
      setCategoryId(productToEdit?.productCategory.id);
      setCost(currencyFormatFloat(productToEdit?.cost));
      setPrice(currencyFormatFloat(productToEdit?.price));
      setDescription(productToEdit?.description);
      setQuantity(productToEdit?.quantity);
      setPicture(productToEdit?.picture);
      setIsHighlighted(productToEdit?.isHighlighted);
      setIsHighlightedString(productToEdit?.isHighlighted);
      setVariablePriceString(productToEdit?.isvariablePrice);
      setIsVariablePrice(productToEdit?.isvariablePrice);
      setSector(productToEdit?.segment?.name);
      setSectorId(productToEdit?.segment?.id);
      setIsEditing(true);
      setDiscountPrice(
          discountPri > 0
            ? currencyFormatFloat(discountPri)
            : 0
        );
      setDiscount(discountAmount);
      setStock(productToEdit?.stock)
    }
  }, [productToEdit]);

  return {
    adminData,
    entityData,
    categoriesSearchList,
    category,
    setCategory,
    setCategoryId,
    step1,
    step2,
    step3,
    loading,
    name,
    setName,
    categoryId,
    description,
    setDescription,
    cost,
    price,
    imageCache,
    picture,
    quantity,
    setQuantity,
    onChangePrice,
    APIErrorMessage,
    hasAPIError,
    isEditing,
    stock,
    setStock,
    setCost,
    setPrice,
    goToStep1,
    goToStep2,
    goToStep3,
    goBack,
    submit,
    onImagePick,
    goToCreateCategory,
    setDiscount,
    onChangeDiscount,
    discountPrice,
    discount,
    isHighlighted,
    highlightedOptionsList,
    setIsHighlighted,
    setIsHighlightedString,
    isHighlightedString,
    sectorsSearchList,
    setSector,
    sector,
    variablePriceList,
    variablePriceString,
    setVariablePriceString,
    setIsVariablePrice,
    setSectorId,
    onChangeSelect,
    onChangeHighlighte,
    onChangeSearch,
    isvariablePrice,
    disable,
  };
};
